import React from "react";
import s from './LatestImages.scss';
import { graphql, Link, useStaticQuery } from "gatsby";
import { Image } from "components/contentful/image/Image";
import DownloadArrow from "assets/svg/download-arrow.svg";
import { Container } from "components/container/Container";
import ArrowSmall from 'assets/svg/arrow-small.svg';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import richTextOptions from "utils/richTextOptions";

const Item = ({item}: any) => {
    return (
        <div className={s('item')}>
            <Image className={s('image')} image={item.image}/>
            <a className={s('overlay')} download href={item.image.file.url} target="_blank">
                Download <DownloadArrow className={s('downloadArrow')}/>
            </a>
        </div>
    )
}

export const LatestImages = ({item}: any) => {

    const data = useStaticQuery(graphql`
    query MyQuery {
        contentfulData: contentfulDownloadSet(title: {eq: "Photography"}) {
          title
          cta {
            label
            href
          }
          items {
            createdAt
            image {
              description
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }`)
    
    const items = data.contentfulData.items.sort((a:any, b:any) => a.createdAt - b.createdAt);
    const {title, text, cta} = item
    return (
        <Container>
            <div className={s('container')}>
                <div className={s('header')}>
                  {
                    title &&
                    <div className={s('title')}>
                        Our Images
                    </div>
                  }
                    
                    <div className={s('headerCta')}>
                      {
                        text && renderRichText(text, richTextOptions)
                      }
                      {
                        cta &&
                        <Link to={cta.href}>
                            {cta.label} <ArrowSmall className={s('arrowRight')}/>
                        </Link>
                      }
                    </div>
                </div>
                <div className={s('content')}>
                    {
                        items.slice(0,4).map((item:any, i:number) => <Item key={i} item={item}/>)
                    }
                </div>
            </div>
        </Container>
    )
}