import { Container } from "components/container/Container";
import React from "react";
import s from './FeaturedArticleCarousel.scss';
import { DateFormatted } from "components/date-formatted/DateFormatted";
import AliceCarousel from "react-alice-carousel";
import { NextDesktopOnly as Next } from "components/quote-carousel/QuoteCarousel";
import { Image } from "components/contentful/image/Image";
import ArrowSmall from 'assets/svg/arrow-small.svg';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Link } from "components/link/Link";
import './carouselOverride.css'
import richTextOptions from "utils/richTextOptions";

const Item = ({item}: any) => {
    return (
        <div className={s('itemContainer')}>
            <div  className={s('item')}>
            {
                item.image &&
                    <div className={s('imageContainer')}>
                        <Image className={s('image')} image={item.image}/>
                    </div>
            }
            
            <div className={s('textContainer')}>
                <div className={s('itemHeader')}>
                    {
                        item.tag &&
                        <div className={s('category')}>
                            {item.tag}
                        </div>
                    }
                    {
                        item.date &&
                        <div className={s('date')}>
                            <DateFormatted date={item.date}/>
                        </div>
                    }
                </div>
                
                {
                    item.title &&
                    <div className={s('title')}>
                        {item.title}
                    </div>
                }
                {
                    item.text &&
                    <div className={s('excerpt')}>
                        {
                            renderRichText(item.text, richTextOptions)
                        }
                    </div>
                }
                {
                    item.cta &&
                    <a href={item.cta.href} target="_blank" className={s('readMore')}>
                        {item.cta.label} <ArrowSmall/>
                    </a>
                }
                </div>
            </div>
        </div>
    )
        
}

export const FeaturedArticleCarousel = ({list}: any) => {
    const items = list.items.map((item: any, i:number) => <Item item={item} key={i}/>);

    return (
        <Container>
            <div className={s('container', 'articles-carousel')}>
                <AliceCarousel
                    items={items}
                    mouseTracking
                    infinite
                    touchTracking
                    renderNextButton={Next}
                    renderPrevButton={() => null}
                    responsive={{0: {items: 1}}}
                />
            </div>
        </Container>
    )
}