import { Container } from "components/container/Container";
import React, { useEffect, useState } from "react";
import s from './PressReleases.scss';
import ArrowSmall from 'assets/svg/arrow-small.svg';
import AliceCarousel from "react-alice-carousel";
import { Next } from "components/quote-carousel/QuoteCarousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from "components/link/Link";
import { graphql, useStaticQuery } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import richTextOptions from "utils/richTextOptions";

const formatDate = (date: string) => {
    const list = date.split(' ');

    return `${list[2]} ${list[1]}, ${'20' + list[3]}`;
}

const Item = ({item}: any) => {
    return (
        <div className={s('item')}>
            <div className={s('itemDate')}>
                {formatDate(item.date)}
            </div>
            <div className={s('itemTitle')}>
                {item.title}
            </div>
            <a className={s('itemCta')} href={item.link} target="_blank">
                Read Press Release <ArrowSmall/>
            </a>
        </div>
    )
}

export const PressReleases = ({item}: any) => {
    const {title, text, cta} = item;
    const [items, setItems]: any[]  = useState([]);

    const action = `/.netlify/functions/rss`;

    const getData = async () => {
        const res = await fetch(action);
        const feed = await res.json()
        const items = feed.map((item: any, i: number) => <Item key={i} item={item}/>);
        setItems(items);
    };
    
    useEffect(() => {
        getData()
    }, [])

    return (
        <Container>
            <div className={s('container')}>
                <div className={s('header')}>
                    {
                        title &&
                        <h4 className={s('title')}>
                            {
                                title
                            }
                        </h4>
                    }
                    <div style={{display: "flex", alignItems: "Center"}}>
                        {
                            text && renderRichText(text, richTextOptions)
                        }
                        {
                            cta && 
                            <Link to={cta.href} target="_blank" className={s('cta')}>
                                {cta.label} <ArrowSmall className={s('arrow')}/>
                            </Link>
                        }
                        
                    </div>
                </div>
                <div className={s('carousel')}>
                    <AliceCarousel
                        items={items}
                        mouseTracking
                        infinite
                        touchTracking
                        renderNextButton={Next}
                        renderPrevButton={() =>{}}
                        renderDotsItem={() => {}}
                        responsive={{
                            0: {items: 1},
                            400: {items: 2}
                        }}
                    />
                </div>
            </div>
        </Container>
    )
}