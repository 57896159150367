import React, { useEffect, useRef } from 'react';
import s from './BgLine2.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"

export const BgLine2 = ({ pageThemeLight=false }) => {

  const lineContainerRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
      gsap.from(lineRef.current, {
        drawSVG: '0%',
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          start: "top bottom",
          end: "top center"
        },
      });
  }, []);

  return (
    <div className={s.section__draw} ref={lineContainerRef} >
      <svg className={s('path__draw__1', {pageThemeLight})}
        preserveAspectRatio='none'
        fill="none" xmlns="http://www.w3.org/2000/svg">
        
        <path ref={lineRef}
        xmlns="http://www.w3.org/2000/svg" opacity="0.2" d="M352.5 0V300.4C352.5 410.857 262.957 500.4 152.5 500.4H0" stroke="#1B52AD" stroke-width="2"/>

      </svg>
    </div>
  );
};