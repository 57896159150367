import { Container } from "components/container/Container";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import s from './SocialBanner.scss'


type ItemProps = {
    item: {
        cta: {
            label: string,
            href: string
        },
        image: {
            gatsbyImageData: any,
            description: string
        }
    }
}

const Item = ({item}: ItemProps) => {
    return (
        <a className={s.link} href={item.cta.href}>
            <GatsbyImage className={s.icon} image={item.image.gatsbyImageData} alt={item.image.description}/>
        </a>
    )
}

export const SocialBanner = ({list}: any) => {
    const {title, subtitle, items} = list;

    return (
    <Container>
        <div className={s.container}>
            <div className={s.banner}>
                <div className={s.titleSection}>
                    {
                        subtitle &&
                        <h4 className={s.subtitle}>
                            {subtitle}
                        </h4>
                    }
                    {
                        title &&
                        <h2 className={s.title}>
                            {title}
                        </h2>
                    }
                </div>
                <div className={s.items}>
                    {
                        items.map((item: any, i: number) => <Item item={item} key={i}/>)
                    }
                </div>
            </div>
        </div>
    </Container>
    )
}