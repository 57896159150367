import { Container } from "components/container/Container";
import React, { useEffect, useState } from "react";
import s from "./News.scss";
import ArrowSmall from "assets/svg/arrow-small.svg";
import { DateFormatted } from "components/date-formatted/DateFormatted";
import AliceCarousel from "react-alice-carousel";
import { Next } from "components/quote-carousel/QuoteCarousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from "components/link/Link";
import Aurora from "assets/svg/logo.svg";
import { graphql, useStaticQuery } from "gatsby";
import { Image } from "components/contentful/image/Image";
import { GatsbyImage } from "gatsby-plugin-image";

const Item = ({ item }: any) => {
  return (
    <div className={s("item")}>
      {item.image && (
        <GatsbyImage
          alt={item.image.description}
          image={item.image.gatsbyImageData}
          imgClassName={s("image")}
          objectFit="contain"
          className={s("image")}
        />
      )}
      {item.title && <h4 className={s("itemTitle")}>{item.title}</h4>}
      <div className={s("text")}>{item.text.text}</div>
      <a className={s("cta")} href={item.cta.href} target="_blank">
        {item.cta.label} <ArrowSmall />
      </a>
    </div>
  );
};

export const News = ({ extraMarginBottom = false }) => {
  const title = "In the news";
  const [items, setItems]: any[] = useState([]);
  const { contentfulData } = useStaticQuery(graphql`
    {
      contentfulData: allContentfulNewsItem(
        sort: { order: DESC, fields: createdAt }
      ) {
        nodes {
          title
          text {
            text
          }
          image {
            description
            gatsbyImageData
          }
          cta {
            href
            label
          }
        }
      }
    }
  `);
  const news = contentfulData.nodes;
  useEffect(() => {
    const newItems = news.map((item: any, i: number) => {
      return <Item item={item} key={i} />;
    });
    setItems(newItems);
  }, []);

  return (
    <Container>
      <div className={s("container", { extraMarginBottom })}>
        <div className={s("header")}>
          {title && <h4 className={s("title")}>{title}</h4>}
        </div>
        <div className={s("carousel")}>
          <AliceCarousel
            items={items}
            mouseTracking
            infinite
            touchTracking
            renderNextButton={Next}
            renderPrevButton={() => {}}
            renderDotsItem={() => {}}
            responsive={{
              0: { items: 1 },
              400: { items: 2 }
            }}
          />
        </div>
      </div>
    </Container>
  );
};
