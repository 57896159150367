import React, { useEffect, useRef } from 'react';
import s from './BgLine4.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"

export const BgLine4 = ({ pageThemeLight=false, style }) => {

  const lineContainerRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
      gsap.from(lineRef.current, {
        drawSVG: '0%',
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start:'top center',
          end: "center bottom"
        },
      });
  }, []);

  return (
    <div className={s.section__draw} ref={lineContainerRef} style={style}>
      <svg className={s('path__draw__1', {pageThemeLight})}
        preserveAspectRatio='none'
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path ref={lineRef} xmlns="http://www.w3.org/2000/svg" opacity="0.2" d="M0 1H124.5C234.957 1 324.5 90.543 324.5 201V323.663H1120.5V376.689C1120.5 487.146 1210.04 576.689 1320.5 576.689H1797.5" stroke="#1B52AD" stroke-width="2"/>
      </svg>
    </div>
  );
};